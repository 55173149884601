import React, { useState, useEffect } from 'react';
import { Layers } from '../../../app-components/ol-map/layers';
import MapLayout from '../../shared-components/mapLayout/mapLayout';
import { WatershedMapContent } from './components';
import { easeGridNorthMapConfig } from '../../shared-components/mapContent/baseMapConfig';
import { store } from '../../../stores/app-state/store';
import { useGetProductFilesQuery } from '../../../stores/server-state/products';
import { useRasterLayersSettings } from '../../shared-components/mapContent/layerSettings';
import { Interactions } from '../../../app-components/ol-map/interactions';
import { PixelQueryInteraction } from '../../../app-components/ol-map/interactions/pixelQueryInteraction';
import RasterLayer from '../../shared-components/mapContent/rasterLayer';

import { WCSDataTile } from '../../shared-components/mapContent/dataTile';
import { sweWcsTileGrid } from '../../../app-components/ol-map/layers/layer-groups/baseLayerGroup';

import 'react-tooltip/dist/react-tooltip.css';

const OlMap = ({ productSlug }) => {
  const [rasterLayerProps, setRasterLayerProps] = useState();
  const [inPrevWaterYearRange, setInPrevWaterYearRange] = useState();

  const { productId } = store.useProductId();
  const { dateSelection: timeISO } = store.useDateSelection();
  const { spatialResolution } = store.useSpatialResolution();

  const { data: productfiles, isLoading } = useGetProductFilesQuery({
    productId,
  });

  const layerSettings = useRasterLayersSettings({
    productId,
    productfiles,
    isLoading,
  });

  // TODO: make spatial resolution part of the product name name, not its own parameter
  // this is just a quick hack for a demo
  let layerNameKey = {
    '25000.0': 'CETB_SWE:nsidc1_daily_swe_last_in_max_n25km',
    '3125.0': 'CETB_SWE:nsidc1_daily_swe_last_in_max_n3_125km',
  };

  let layerName = layerNameKey[spatialResolution];

  const wcsBaseUrl = `https://staging.nsidc.org/api/mapservices/CETB_SWE/wcs`;

  useEffect(() => {
    const [year, month, day] = timeISO.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    const prevWaterYearDate = new Date(
      selectedDate > new Date(new Date().getFullYear(), 8, 1)
        ? new Date().getFullYear() - 1
        : new Date().getFullYear() - 2,
      8,
      1,
    );

    setInPrevWaterYearRange(
      selectedDate < new Date() && selectedDate >= prevWaterYearDate,
    );

    if (selectedDate < new Date() && selectedDate >= prevWaterYearDate) {
      let selectProductfile = productfiles
        ? productfiles.find(
            ps =>
              ps.datetime.split('T')[0] === timeISO &&
              ps.spatial_resolution === spatialResolution,
          )
        : {};

      const rLProps =
        layerSettings.length && selectProductfile
          ? layerSettings.find(ls => ls.id === selectProductfile.id)
          : {};
      setRasterLayerProps(rLProps);
    } else {
      setRasterLayerProps(layerSettings.length ? layerSettings[0] : {});
    }
  }, [layerSettings, timeISO, spatialResolution]);

  return (
    <MapLayout productSlug={productSlug} mapConfig={easeGridNorthMapConfig}>
      <Layers>
        <WatershedMapContent />
        {inPrevWaterYearRange ? (
          <>{layerSettings && <RasterLayer {...rasterLayerProps} />}</>
        ) : (
          <>
            <WCSDataTile
              {...rasterLayerProps}
              name={`${layerName}_${timeISO}`}
              baseUrl={wcsBaseUrl}
              layerName={layerName}
              timeISO={timeISO}
              tileGrid={sweWcsTileGrid}
            />
          </>
        )}
      </Layers>
      <Interactions>
        <PixelQueryInteraction />
      </Interactions>
    </MapLayout>
  );
};

export default OlMap;
